<template>
  <div class="bg-white py-24 sm:py-32" id="platform">
    <div class="mx-auto max-w-7xl px-6 lg:px-8 flex gap-32 flex-col">
      <div>
        <div class="mx-auto max-w-2xl text-center" id="podcasts" data-aos="fade">
          <h2 class="text-balance text-center text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl archivo-black-regular uppercase" :style="animatedStyle">Les Podcasts</h2>
          <p class="mt-2 text-lg/8 text-gray-600" >
            Les podcasts ont pour but de partager des expériences, donner des conseils, et bien plus encore.
          </p>
        </div>
        <div class="mx-auto mt-8 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3" data-aos="fade-right">
          <a :href="video.href"  v-for="video in videos" :key="video.id" class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-96 sm:pt-48 lg:pt-96">
            <video :src="video.videoUrl" autoplay muted loop playsinline class="absolute inset-0 -z-10 size-full object-cover"></video>
            <div class="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
            <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

            <div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm/6 text-gray-300">
              <time :datetime="video.datetime" class="mr-8">{{ video.date }}</time>
              <div class="-ml-4 flex items-center gap-x-4">
                <svg viewBox="0 0 2 2" class="-ml-0.5 size-0.5 flex-none fill-white/50">
                  <circle cx="1" cy="1" r="1" />
                </svg>
              </div>
            </div>
            <h3 class="mt-3 text-lg/6 font-semibold text-white">
              <span class="archivo-black-regular">
                <span class="absolute inset-0" />
                {{ video.title }}
              </span>
            </h3>
          </a>
        </div>
        <div class="w-full flex justify-center mt-8 ">
          <a href="/podcasts" class="archivo-black-regular" :style="animatedStyle">Tout voir</a>
        </div>
      </div>

      <hr>

      <div>
        <div class="mx-auto max-w-2xl text-center" data-aos="fade">
          <h2 class="text-balance text-center text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl archivo-black-regular uppercase" :style="animatedStyle">Les Nouveautés</h2>
          <p class="mt-2 text-lg/8 text-gray-600">
            Retrouvez tous les derniers articles concernant l'informatique, l'innovation, la technologie, et bien plus encore.
          </p>
        </div>
        <div class="mx-auto mt-8 grid max-w-2xl grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none" data-aos="fade-left">
          <a :href="post.href" v-for="post in posts" :key="post.id" class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80">
            <img :src="post.images" alt="" class="absolute inset-0 -z-10 size-full object-cover" />
            <div class="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
            <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

            <div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm/6 text-gray-300">
              <time :datetime="post.datetime" class="mr-8">{{ post.date }}</time>
              <div class="-ml-4 flex items-center gap-x-4">
                <svg viewBox="0 0 2 2" class="-ml-0.5 size-0.5 flex-none fill-white/50">
                  <circle cx="1" cy="1" r="1" />
                </svg>
              </div>
            </div>
            <h3 class="mt-3 text-lg/6 font-semibold text-white">
              <span class="archivo-black-regular">
                <span class="absolute inset-0" />
                {{ post.title }}
              </span>
            </h3>
          </a>
        </div>
        <div class="w-full flex justify-center mt-8 ">
          <a href="/articles" class="archivo-black-regular" :style="animatedStyle">Tout voir</a>
        </div>
      </div>
    </div>
  </div>
  <Space></Space>
</template>

<script setup>
import { onMounted, ref } from "vue";
import Space from "@/components/Essentials/Space.vue";

const videos = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl:
        'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl:
        'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl:
        'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
]

const posts = ref([]);

const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

async function fetchArticles() {
  try {
    const response = await fetch("https://api.hbc-group.fr/api/article");
    const data = await response.json();

    data.sort((a, b) => new Date(b.date) - new Date(a.date));

    posts.value = data.slice(0, 3).map(post => ({
      id: post.id,
      title: post.title,
      href: "#",
      short_description: post.short_description,
      images: post.images,
      date: new Date(post.date).toLocaleDateString(),
      datetime: post.date,
    }));
  } catch (error) {
    console.error("Erreur lors de la récupération des articles:", error);
  }
}

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;

  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }

  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  requestAnimationFrame(animateGradient);
}

onMounted(() => {
  animateGradient();
  fetchArticles();
});
</script>
