<script setup>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Load from "@/components/Essentials/Banner.vue";
import {onMounted, ref} from "vue";
import {gsap} from "gsap";
import Newsletter from "@/components/Essentials/Newsletter.vue";


const gradientPosition = ref(0); // Position initiale
const direction = ref(1); // Direction du mouvement : 1 = aller, -1 = retour
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)", // Indigo -> Violet -> Rose
  backgroundSize: "200% 200%", // Taille du dégradé
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%", // Position initiale
});

function animateGradient() {
  // Met à jour la position du dégradé
  gradientPosition.value += direction.value * 1.3; // Ajuste la vitesse (0.5 ici)

  // Inversion de la direction en cas de dépassement
  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1; // Change de sens
  }

  // Applique la nouvelle position
  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  // Relance l'animation
  requestAnimationFrame(animateGradient);
}

const wavePath = document.getElementById('wave-path');

// Animation avec GSAP
onMounted(() => {
  const wavePathFg = document.getElementById('wave-path-fg');

  if (wavePathFg) {
    gsap.to(wavePathFg, {
      duration: 2, // Durée plus rapide pour un contraste de mouvement
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }
  animateGradient();
});

</script>

<template>
  <div class="relative">
    <Load></Load>
    <AppHeader></AppHeader>
    <div class="relative isolate px-6 lg:px-8 pb-56">

      <div class="absolute left-0 bottom-0 inline-flex w-full">
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
          <path id="wave-path-fg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
        </svg>
      </div>
    </div>
    <img src="@/assets/bg.jpg" alt="" class="absolute inset-0 -z-10 size-full object-cover">
  </div>
  <div class="mx-auto max-w-7xl px-6 sm:px-0">
    <a href="/articles" class="archivo-black-regular" :style="animatedStyle">Retour</a>
  </div>

  <div class="bg-white px-6 py-24 lg:px-8">
    <div class="mx-auto max-w-7xl text-base/7 text-gray-700">
      <h1 class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-800 sm:text-5xl uppercase archivo-black-regular">L'évolution de l'intelligence artificielle</h1>
      <figure class="mt-12 mb-8">
        <img class="aspect-video rounded-xl bg-gray-50 object-cover" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3" alt="" />
      </figure>
      <p class="mt-6 text-xl/8">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
      <div class="mt-10 max-w-7xl">
        <h2 class="text-pretty text-3xl font-semibold tracking-tight text-gray-800 archivo-black-regular uppercase">Everything you need to get up and running</h2>
        <p class="mt-6">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
        <p class="mt-6">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
        <p class="mt-6">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.</p>
      </div>

      <div class="mt-16 max-w-7xl">
        <h2 class="text-pretty text-3xl font-semibold tracking-tight text-gray-800 archivo-black-regular uppercase">Everything you need to get up and running</h2>
        <p class="mt-6">Purus morbi dignissim senectus mattis adipiscing. Amet, massa quam varius orci dapibus volutpat cras. In amet eu ridiculus leo sodales cursus tristique. Tincidunt sed tempus ut viverra ridiculus non molestie. Gravida quis fringilla amet eget dui tempor dignissim. Facilisis auctor venenatis varius nunc, congue erat ac. Cras fermentum convallis quam.</p>
        <p class="mt-6">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.</p>
      </div>
    </div>
  </div>
  <Newsletter></Newsletter>
  <AppFooter></AppFooter>
</template>
