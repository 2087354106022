<template>
  <div class="overflow-hidden bg-white py-24 sm:py-32" id="about">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-20 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:ml-auto lg:pl-4" data-aos="fade-left">
          <div class="lg:max-w-xl text-center">
            <h1 class="archivo-black-regular text-pretty text-4xl font-semibold tracking-tight uppercase sm:text-5xl" :style="animatedStyle">
              Qui sommes-nous ?
            </h1>
            <p class="mt-6 text-lg/8 text-gray-600">
              Nous sommes une <strong class="text-indigo-600">agence passionnée</strong> par la création de solutions digitales sur mesure. Grâce à notre expertise, nous concevons des sites web innovants et performants, adaptés aux besoins uniques de chaque client.
              <br><br><strong class="text-indigo-600">Notre objectif :</strong> aider à faire croître votre entreprise avec des outils web puissants, accessibles et parfaitement adaptés à vos besoins.
            </p>

            <dl class="mt-8 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none text-left bg-neutral-50 p-4 rounded-md">
              <div v-for="feature in features" :key="feature.name" class="relative pl-9">
                <dt class="inline font-semibold text-gray-900 mr-2">
                  <component :is="feature.icon" class="absolute left-1 top-1 size-5 text-indigo-600 mr" aria-hidden="true" />
                  {{ feature.name }}
                </dt>
                <dd class="inline">{{ feature.description }}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="flex items-start justify-end lg:order-first relative" data-aos="fade-right">
          <img src="@/assets/app.png" alt="Product screenshot" class=" w-[48rem] h-[44rem] max-w-none rounded-xl object-cover shadow-xl ring-1 ring-gray-400/10 sm:w-[68rem]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ClockIcon, ChatBubbleLeftIcon, StarIcon } from '@heroicons/vue/20/solid'
import { onMounted, ref } from "vue";

const features = [
  {
    name: 'Rapidité',
    description:
        'Nous assurons un développement et un déploiement rapide pour que vos projets soient livrés dans les délais impartis, sans compromettre la qualité.',
    icon: ClockIcon,
  },
  {
    name: 'Communication',
    description: 'Nous mettons un point d’honneur à maintenir une communication claire et transparente avec nos clients tout au long du projet.',
    icon: ChatBubbleLeftIcon,
  },
  {
    name: 'Qualité',
    description: 'Chaque projet que nous réalisons est conçu pour répondre aux plus hauts standards de qualité, assurant une expérience optimale pour vos utilisateurs.',
    icon: StarIcon,
  },
]

const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;

  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }

  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  requestAnimationFrame(animateGradient);
}

onMounted(() => {
  animateGradient();
});
</script>
