<script setup>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Banner from "@/components/Essentials/Banner.vue";
import {onMounted, ref} from "vue";
import {gsap} from "gsap";
import AOS from 'aos';
import 'aos/dist/aos.css';


const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;

  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }

  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  requestAnimationFrame(animateGradient);
}

const wavePath = document.getElementById('wave-path');

onMounted(() => {
  const wavePathFg = document.getElementById('wave-path-fg');

  if (wavePathFg) {
    gsap.to(wavePathFg, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }
  animateGradient();
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
  });
});

</script>

<template>
  <div class="relative">
    <Banner></Banner>
    <AppHeader></AppHeader>
    <div class="relative isolate px-6 lg:px-8 pb-56">
      <div class="absolute left-0 bottom-0 inline-flex w-full">
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
          <path id="wave-path-fg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
        </svg>
      </div>
    </div>
    <img src="@/assets/bg.jpg" alt="" class="absolute inset-0 -z-10 size-full object-cover">
  </div>
  <div class="relative bg-white">
    <div class="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:max-w-7xl lg:pt-32">
      <div class="px-6 lg:px-8">
        <div class="mx-auto">
          <h2 class="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl uppercase archivo-black-regular" data-aos="fade-down" :style="animatedStyle">Formulaire de Demande – Offre Standard</h2>
          <p class="mt-8 text-lg/8 text-gray-600" data-aos="fade-down">Remplissez ce formulaire pour nous fournir toutes les informations nécessaires à la création de votre site web. Nous traiterons votre demande rapidement !</p>
          <form action="#" method="POST" class="mt-16">
            <!-- Informations de Contact -->
            <h3 class="text-lg font-semibold text-gray-900 archivo-black-regular uppercase" data-aos="fade-down":style="animatedStyle">Informations de Contact</h3>
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 mt-4" data-aos="fade-down">
              <div>
                <label for="full-name" class="block text-sm font-semibold text-gray-900">Nom complet</label>
                <input type="text" name="full-name" id="full-name" required class="block mt-2 w-full rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600" />
              </div>
              <div>
                <label for="email" class="block text-sm font-semibold text-gray-900">Adresse e-mail</label>
                <input type="email" name="email" id="email" required class="block mt-2 w-full rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600" />
              </div>
              <div class="sm:col-span-2">
                <label for="phone" class="block text-sm font-semibold text-gray-900">Numéro de téléphone (optionnel)</label>
                <input type="tel" name="phone" id="phone" class="block w-full rounded-md mt-2 px-3.5 py-2 outline outline-2 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600" />
              </div>
            </div>

            <!-- Informations sur l'Entreprise -->
            <h3 class="text-lg font-semibold text-gray-900 mt-24 archivo-black-regular uppercase" data-aos="fade-down" :style="animatedStyle">Informations sur l'Entreprise</h3>
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 mt-4" data-aos="fade-down">
              <div class="sm:col-span-2">
                <label for="company" class="block text-sm font-semibold text-gray-900">Nom de l’entreprise</label>
                <input type="text" name="company" id="company" required class="block w-full mt-2 rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600" />
              </div>
              <div>
                <label for="industry" class="block text-sm font-semibold text-gray-900">Secteur d’activité</label>
                <input type="text" name="industry" id="industry" required class="block w-full mt-2 rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600" />
              </div>
              <div>
                <label for="website" class="block text-sm font-semibold text-gray-900">Lien vers votre site actuel (si applicable)</label>
                <input type="url" name="website" id="website" class="block w-full rounded-md mt-2 px-3.5 py-2 outline outline-2 outline-gray-300 placeholder:text-gray-400 focus:outline-indigo-600" />
              </div>
            </div>

            <!-- Type de Site Souhaité -->
            <h3 class="text-lg font-semibold text-gray-900 mt-24 archivo-black-regular uppercase" data-aos="fade-down" :style="animatedStyle">Type de Site Souhaité</h3>
            <div class="mt-4" data-aos="fade-down">
              <label for="site-type" class="block text-sm font-semibold text-gray-900">Quel type de site voulez-vous ?</label>
              <select name="site-type" id="site-type" required class="block w-full mt-2 rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600">
                <option value="" disabled selected>Sélectionnez un type de site</option>
                <option value="vitrine">Site vitrine</option>
                <option value="ecommerce">Site e-commerce</option>
                <option value="blog">Blog</option>
                <option value="portfolio">Portfolio</option>
                <option value="autre">Autre</option>
              </select>
            </div>

<!--            -->
            <h3 class="text-lg font-semibold text-gray-900 mt-24 archivo-black-regular uppercase" data-aos="fade-down" :style="animatedStyle">Nom de domaine</h3>
            <div class="mt-4" data-aos="fade-down">
              <label for="domaine" class="block text-sm font-semibold text-gray-900">Avez-vous un nom de domaine ?</label>
              <select name="domaine" id="domaine" required class="block w-full mt-2 rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600">
                <option value="" disabled selected>Choisissez une option</option>
                <option value="yes">Oui</option>
                <option value="no">Non</option>
              </select>
            </div>

            <!-- Détails du Projet -->
            <h3 class="text-lg font-semibold text-gray-900 mt-24 archivo-black-regular uppercase" data-aos="fade-down" :style="animatedStyle">Détails du Projet</h3>
            <div class="grid grid-cols-1 gap-y-6 mt-4" data-aos="fade-down">
              <div>
                <label for="objectives" class="block text-sm font-semibold text-gray-900">Objectif principal du site</label>
                <input type="text" name="objectives" id="objectives" placeholder="Ex. : Génération de leads, E-commerce..." required class="block mt-2 w-full rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600" />
              </div>
              <div>
                <label for="pages" class="block text-sm font-semibold text-gray-900">Pages spécifiques souhaitées</label>
                <textarea id="pages" name="pages" rows="3" placeholder="Ex. : Accueil, À propos, Services..." class="block mt-2 w-full rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600"></textarea>
              </div>
              <div>
                <label for="content-ready" class="block text-sm font-semibold text-gray-900">Contenus déjà prêts ?</label>
                <select name="content-ready" id="content-ready" required class="block w-full mt-2 rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600">
                  <option value="" disabled selected>Choisissez une option</option>
                  <option value="yes">Oui</option>
                  <option value="no">Non</option>
                </select>
              </div>
            </div>

            <!-- Préférences de Design -->
            <h3 class="text-lg font-semibold text-gray-900 mt-24 archivo-black-regular uppercase" data-aos="fade-down" :style="animatedStyle">Préférences de Design</h3>
            <div class="grid grid-cols-1 gap-y-6 mt-4" data-aos="fade-down">
              <div>
                <label for="design-examples" class="block text-sm font-semibold text-gray-900">Exemples de sites que vous aimez</label>
                <textarea id="design-examples" name="design-examples" rows="3" placeholder="Ajoutez des liens ou décrivez vos inspirations." class="block mt-2 w-full rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600"></textarea>
              </div>
              <div>
                <label for="design-avoid" class="block text-sm font-semibold text-gray-900">Couleurs ou styles à éviter ?</label>
                <textarea id="design-avoid" name="design-avoid" rows="2" placeholder="Ex. : Pas de couleurs vives." class="block w-full mt-2 rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600"></textarea>
              </div>
            </div>

            <!-- Date de Livraison -->
            <h3 class="text-lg font-semibold text-gray-900 mt-24 archivo-black-regular uppercase" data-aos="fade-down" :style="animatedStyle">Date de Livraison</h3>
            <div class="mt-4" data-aos="fade-down">
              <label for="delivery-date" class="block text-sm font-semibold text-gray-900">Est-ce que 2 semaines suffisent ?</label>
              <select name="delivery-date" id="delivery-date" required class="block w-full mt-2 rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600">
                <option value="" disabled selected>Choisissez une option</option>
                <option value="yes">Oui</option>
                <option value="no">Non</option>
              </select>
            </div>

            <!-- Commentaires supplémentaires -->
            <h3 class="text-lg font-semibold text-gray-900 mt-24 archivo-black-regular uppercase" data-aos="fade-down" :style="animatedStyle">Commentaires supplémentaires</h3>
            <div class="mt-4" data-aos="fade-down">
              <textarea id="additional-comments" name="additional-comments" rows="4" placeholder="Ajoutez tout détail ou information utile." class="block w-full rounded-md px-3.5 py-2 outline outline-2 outline-gray-300 focus:outline-indigo-600"></textarea>
            </div>

            <!-- Consentement -->
            <div class="mt-8" data-aos="fade-down">
              <label class="inline-flex items-center">
                <input type="checkbox" name="consent" required class="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                <span class="ml-2 text-sm text-gray-600">J’accepte que mes données soient utilisées pour traiter ma demande, conformément à la politique de confidentialité.</span>
              </label>
            </div>

            <!-- CTA -->
            <div class="mt-10 flex justify-end" data-aos="fade-right">
              <button type="submit" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Soumettre ma demande</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <AppFooter></AppFooter>
</template>
