<template>
  <header :class="{'fixed top-0 w-full z-50 transition-all': isScrolled, 'relative': !isScrolled}" >
    <nav :class="{'bg-opacity-90 bg-black shadow-lg': isScrolled, 'bg-transparent': !isScrolled}" class="mx-auto flex max-w-8xl items-center justify-between px-6 py-4 lg:px-12 transition-colors duration-300" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="/" class="-m-1.5 p-1.5 flex items-center gap-2">
          <img class="h-12 w-auto" src="@/assets/icon.png" alt="HBC Logo" />
          <span class="font-black text-white archivo-black-regular text-md lg:text-xl">HBC GROUP</span>
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="size-6" aria-hidden="true" />
        </button>
      </div>
      <PopoverGroup class="hidden lg:flex lg:gap-x-12">
        <Popover class="relative">
          <PopoverButton class="flex items-center gap-x-1 text-sm/6 archivo-black-regular text-white focus:outline-none">
            Produits
            <ChevronDownIcon class="size-5 flex-none text-gray-50" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-opacity-60 bg-white shadow-lg ring-1 ring-gray-900/5">
              <div class="p-4">
                <div v-for="item in products" :key="item.name" class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50">
                  <div class="flex-auto">
                    <a :href="item.href" class="block font-semibold text-gray-900 archivo-black-regular">
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </a>
                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <Popover class="relative">
          <PopoverButton class="flex items-center gap-x-1 text-sm/6 archivo-black-regular text-white focus:outline-none">
            Plateforme
            <ChevronDownIcon class="size-5 flex-none text-gray-50" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-opacity-60 bg-white shadow-lg ring-1 ring-gray-900/5">
              <div class="p-4">
                <div v-for="item in platform" :key="item.name" class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50">
                  <div class="flex-auto">
                    <a :href="item.href" class="block font-semibold text-gray-900 archivo-black-regular">
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </a>
                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <a href="#offers" class="text-sm/6 font-semibold text-white archivo-black-regular">Offres</a>
        <a href="#about" class="text-sm/6 font-semibold text-white archivo-black-regular">À Propos</a>
      </PopoverGroup>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a href="/espace-de-connexion" class="text-sm/6 font-semibold text-gray-900 bg-white px-6 py-2 bg-opacity-90 hover:bg-opacity-100 rounded-full">
          Se connecter <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </nav>

    <!-- Mobile -->
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="mobileMenuOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>
        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-black text-white px-6 py-6">
              <button type="button" class="absolute top-4 right-4 -m-2.5 p-2.5 text-gray-300" @click="mobileMenuOpen = false">
                <XMarkIcon class="size-6" aria-hidden="true" />
              </button>
              <nav class="mt-6 space-y-6">
                <div class="flex gap-2 items-center">
                  <img src="@/assets/icon.png" class="h-12" alt="Logo" />
                  <h1 class="archivo-black-regular">HBC GROUP</h1>
                </div>
                <div class="space-y-4">
                  <div v-for="item in products" :key="item.name" class="block rounded-lg px-3 py-2 bg-neutral-900 hover:bg-neutral-800">
                    <a :href="item.href" class="flex items-center gap-x-2">
                      <span class="font-semibold archivo-black-regular">{{ item.name }}</span>
                    </a>
                    <p class="text-gray-400 text-sm">{{ item.description }}</p>
                  </div>
                </div>
                <a href="/podcasts" class="block archivo-black-regular text-lg font-semibold hover:bg-gray-700 rounded-lg px-3 py-2">Podcasts</a>
                <a href="/articles" class="block archivo-black-regular text-lg font-semibold hover:bg-gray-700 rounded-lg px-3 py-2">Articles</a>
                <a href="#offers" class="block archivo-black-regular text-lg font-semibold hover:bg-gray-700 rounded-lg px-3 py-2">Offres</a>
                <a href="#about" class="block archivo-black-regular text-lg font-semibold hover:bg-gray-700 rounded-lg px-3 py-2">À Propos</a>
                <a href="/espace-de-connexion" class="block archivo-black-regular text-lg bg-white text-gray-900 rounded-lg px-3 py-2">Se connecter</a>
              </nav>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';

const products = [
  { name: 'Kuube', description: 'Créer un site web sans aucune connaissance n\'a jamais été aussi facile.', href: '#', icon: ChevronDownIcon },
  { name: 'Etudik', description: 'Une application de prise de notes spécialement conçue pour les étudiants.', href: '#', icon: ChevronDownIcon },
];

const platform = [
  { name: 'Podcasts', description: 'Les podcasts ont pour but de partager des expériences, donner des conseils, et bien plus encore.', href: '/podcasts', icon: ChevronDownIcon },
  { name: 'Articles', description: 'Retrouvez tous les derniers articles concernant l\'informatique, l\'innovation, la technologie, et bien plus encore.', href: '/articles', icon: ChevronDownIcon },
];

const mobileMenuOpen = ref(false);
const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 50;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
