<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

  <router-view />
</template>
<script>

</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Nabla&family=Poppins:wght@300&family=Rubik+Scribble&display=swap');

@font-face {
  font-family: 'ArchivoBlack';
  src: url('@/assets/fonts/ArchivoBlack-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Lato', sans-serif;
}

.archivo-black-regular {
  font-family: 'ArchivoBlack', sans-serif;
  font-weight: normal;
}

</style>
