<template>
  <div class="bg-white mt-12">
    <div class="mx-auto max-w-7xl">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:mt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <a :href="post.href" v-for="post in posts" :key="post.id" class="flex max-w-xl border hover:border-indigo-500 hover:bg-indigo-50 flex-col items-start justify-between bg-neutral-50 p-4 rounded-lg">
          <div class="flex items-center gap-x-4 text-xs">
            <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
            <span :class="[type[post.category.title], 'relative z-10 rounded-full  px-3 py-1.5 font-medium']">{{ post.category.title }}</span>
          </div>
          <div class="group relative">
            <h3 class="mt-3 text-lg/6 font-semibold text-gray-900">
              <span>
                <span class="absolute inset-0" />
                {{ post.title }}
              </span>
            </h3>
            <p class="mt-5 line-clamp-3 text-sm/6 text-gray-600 archivo-black-regular uppercase">{{ post.description }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
const type = {
  Standard: "text-sky-700 bg-sky-50 ring-sky-600/20",
  Personnalisé: "text-green-700 bg-green-50 ring-green-600/20",
};

const posts = [
  {
    id: 1,
    title: 'Attente de paiement',
    href: '#',
    description:
        'Adore',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    category: { title: 'Standard'},
  },
  {
    id: 2,
    title: 'Développement en cours',
    href: '#',
    description:
        'Orféo',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    category: { title: 'Personnalisé' },
  },
  {
    id: 3,
    title: 'En cours de validation',
    href: '#',
    description:
        'Fioto',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    category: { title: 'Personnalisé' },
  },
]
</script>