<template>
  <div class=" py-32 sm:py-52 relative">
    <div class="mx-auto max-w-7xl px-6 lg:px-8" data-aos="fade-right">
      <h2 class="text-center text-4xl lg:text-5xl font-semibold text-white archivo-black-regular uppercase">Ils nous font confiance</h2>
      <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/ad_logo.png" alt="Transistor" width="158" height="48" />
        <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/reform-logo-white.svg" alt="Reform" width="158" height="48" />
        <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/tuple-logo-white.svg" alt="Tuple" width="158" height="48" />
        <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/savvycal-logo-white.svg" alt="SavvyCal" width="158" height="48" />
        <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/statamic-logo-white.svg" alt="Statamic" width="158" height="48" />
      </div>
    </div>

    <div class="absolute left-0 bottom-0 inline-flex w-full">
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
        <path id="wave-partners-path-fg" ref="wavePartnersPathFg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
      </svg>
    </div>
    <div class="absolute left-0 top-0 rotate-180 inline-flex w-full">
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
        <path id="wave-partners-top-path-fg" ref="wavePartnersTopPathFg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
      </svg>
    </div>
    <img src="@/assets/bg.jpg" alt="" class="absolute inset-0 -z-10 size-full object-cover rotate-180">
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { gsap } from "gsap";

const wavePartnersPathFg = ref(null);
const wavePartnersTopPathFg = ref(null);

onMounted(() => {
  if (wavePartnersPathFg.value) {
    gsap.to(wavePartnersPathFg.value, {
      duration: 3,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }

  if (wavePartnersTopPathFg.value) {
    gsap.to(wavePartnersTopPathFg.value, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }
});

</script>
