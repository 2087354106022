<template>
  <div class="isolate overflow-hidden relative" id="offers" data-aos="fade-up">
    <div class="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
      <div class="mx-auto max-w-4xl">
        <h2 class="text-base/7 font-semibold text-orange-100">Offres</h2>
        <p class="mt-2 text-balance text-4xl font-extrabold tracking-tight text-white sm:text-5xl uppercase archivo-black-regular">La solution efficace à moindre coût</p>
      </div>
    </div>
    <div class="flow-root bg-white pb-24 sm:pb-32">
      <div class="-mt-80">
        <div class="mx-auto max-w-7xl px-6 lg:px-8 ">
          <div class="mx-auto grid max-w-md grid-cols-1 lg:max-w-4xl">
            <div v-for="tier in tiers" :key="tier.id" class="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
              <div>
                <h3 :id="tier.id" class="text-base/7 font-semibold text-indigo-600">{{ tier.name }}</h3>
                <div class="mt-4 flex items-baseline gap-x-2">
                  <span class="text-5xl font-semibold tracking-tight text-gray-900">{{ tier.priceMonthly }}</span>
                  <span class="text-base/7 font-semibold text-gray-600">/mois</span>
                </div>
                <p class="mt-6 text-base/7 text-gray-600">{{ tier.description }}</p>
                <ul role="list" class="mt-10 space-y-4 text-sm/6 text-gray-600">
                  <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
                    <CheckIcon class="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {{ feature }}
                  </li>
                </ul>
              </div>
              <a :href="tier.href" :aria-describedby="tier.id" class="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Démarrer maintenant</a>
            </div>

            <div class="flex mt-12 flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
              <div class="lg:min-w-0 lg:flex-1">
                <h3 class="text-base/7 font-semibold text-indigo-600">Personnalisation complète</h3>
                <p class="mt-1 text-base/7 text-gray-600">Vous avez des besoins spécifiques ? Nous pouvons créer un site 100% sur mesure pour vous, en fonction de vos attentes et de votre budget.</p>
              </div>
              <a href="#" class="rounded-md px-3.5 py-2 text-sm/6 font-semibold text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Demander un devis <span aria-hidden="true">&rarr;</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="absolute left-0 top-0 rotate-180 inline-flex w-full">
      <svg width="100%" height="150" xmlns="http://www.w3.org/2000/svg">
        <path
            id="wave-offer-path-fg"
            d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 1800 150 L 0 150 Z"
            fill="#FFFFFF"
        ></path>
      </svg>
    </div>
    <img src="@/assets/bg.jpg" alt="" class="max-w-7xl flex rounded-lg mx-auto absolute inset-0 -z-10 size-full object-cover">
  </div>
</template>

<script setup>
import { gsap } from 'gsap';
import {onMounted} from 'vue'
import {CheckIcon} from '@heroicons/vue/20/solid'

onMounted(() => {
  const wavePathFg = document.getElementById('wave-offer-path-fg');

  if (wavePathFg) {
    gsap.to(wavePathFg, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 1800 150
          L 0 150 Z
        `,
      },
    });
  }
});

const tiers = [
  {
    name: 'Offre Standard',
    id: 'standard',
    href: '/demande-standard',
    priceMonthly: '400€',
    description: 'Nous créons votre site avec un modèle pré-défini, adapté à votre secteur. Personnalisation de base pour que votre site soit unique tout en restant abordable.',
    features: ['5 pages incluses', 'Design moderne et responsive', 'Intégration de vos contenus', 'Mise en ligne rapide (2 semaines)'],
  },
]
</script>
