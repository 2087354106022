<template>
  <form action="#" method="POST" class="space-y-6">
    <div class="rounded-md bg-white px-3 pb-1.5 pt-2.5 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
      <label for="email" class="block text-xs font-medium text-gray-900">Email</label>
      <input type="email" name="email" id="email" class="block w-full text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6" placeholder="jane.smith@email.com" />
    </div>

    <div class="rounded-md bg-white px-3 pb-1.5 pt-2.5 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
      <label for="password" class="block text-xs font-medium text-gray-900">Mot de passe</label>
      <input type="password" name="password" id="password" class="block w-full text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6" placeholder="********" />
    </div>

    <div class="flex items-center justify-between">
      <div class="flex gap-3">
        <div class="flex h-6 shrink-0 items-center">
          <div class="group grid size-4 grid-cols-1">
            <input id="remember-me" name="remember-me" type="checkbox" class="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"/>
          </div>
        </div>
        <label for="remember-me" class="block text-sm/6 text-gray-900">Se souvenir de moi</label>
      </div>

      <div class="text-sm/6">
        <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Mot de passe oublié ?</a>
      </div>
    </div>

    <div>
      <button
          type="submit"
          class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Connexion
      </button>
    </div>
    <div>
      <p class="mt-2 text-sm/6 text-gray-500">
        Pas encore membre ?
        <a href="#" @click.prevent="$emit('switchForm')" class="font-semibold text-indigo-600 hover:text-indigo-500">S'inscrire.</a>
      </p>
    </div>
  </form>
</template>