<template>
  <form action="#" method="POST" class="space-y-6">
    <div v-if="currentStep === 1">
      <div class="mt-2">
        <div class="rounded-md bg-white px-3 pb-1.5 pt-2.5 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
          <label for="firstname" class="block text-xs font-medium text-gray-900">Prénom</label>
          <input type="text" v-model="form.firstname" name="firstname" id="firstname" class="block w-full text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6" placeholder="Jane" />
        </div>
        <p v-if="errors.firstname" class="text-sm text-red-500 mt-2">{{ errors.firstname }}</p>
      </div>
      <button @click.prevent="validateStep" class="flex mt-6 w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Suivant</button>
    </div>

    <div v-if="currentStep === 2">
      <div class="mt-2">
        <div class="rounded-md bg-white px-3 pb-1.5 pt-2.5 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
          <label for="lastname" class="block text-xs font-medium text-gray-900">Nom</label>
          <input type="text" v-model="form.lastname" name="lastname" id="lastname" class="block w-full text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6" placeholder="Smith" />
        </div>
        <p v-if="errors.lastname" class="text-sm text-red-500 mt-2">{{ errors.lastname }}</p>
      </div>
      <button @click.prevent="validateStep" class="flex mt-6 w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Suivant</button>
    </div>

    <div v-if="currentStep === 3">
      <div class="mt-2">
        <div class="rounded-md bg-white px-3 pb-1.5 pt-2.5 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
          <label for="lastname" class="block text-xs font-medium text-gray-900">Email</label>
          <input type="email" v-model="form.email" name="email" id="email" class="block w-full text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6" placeholder="jane.smith@email.com" />
        </div>
        <p v-if="errors.email" class="text-sm text-red-500 mt-2">{{ errors.email }}</p>
      </div>
      <button @click.prevent="validateStep" class="flex mt-6 w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Suivant</button>
    </div>

    <div v-if="currentStep === 4">
      <div class="mt-2">
        <div class="rounded-md bg-white px-3 pb-1.5 pt-2.5 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
          <label for="password" class="block text-xs font-medium text-gray-900">Mot de passe</label>
          <input type="password" v-model="form.password" name="password" id="password" class="block w-full text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6" placeholder="*********"/>
        </div>
        <p v-if="errors.password" class="text-sm text-red-500 mt-2">{{ errors.password }}</p>
      </div>
      <button @click.prevent="validateStep" class="flex mt-6 w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Suivant</button>
    </div>

    <div v-if="currentStep === 5">
      <div class="mt-2">
        <div class="rounded-md bg-white px-3 pb-1.5 pt-2.5 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
          <label for="confirm" class="block text-xs font-medium text-gray-900">Confirmation</label>
          <input type="password" v-model="form.confirm" name="confirm" id="confirm" class="block w-full text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6" placeholder="*********" />
        </div>
        <p v-if="errors.confirm" class="text-sm text-red-500 mt-2">{{ errors.confirm }}</p>
      </div>
      <button @click.prevent="submitForm" type="submit" class="flex mt-6 w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        S'inscrire
      </button>
    </div>
    <div>
      <p class="mt-2 text-sm/6 text-gray-500">
        Déjà membre ?
        <a href="#" @click.prevent="$emit('switchForm')" class="font-semibold text-indigo-600 hover:text-indigo-500">Se connecter.</a>
      </p>

      <p class="text-sm text-gray-400 mt-8">
        En vous inscrivant, vous acceptez nos
        <a href="#" class="font-semibold text-indigo-400 hover:text-indigo-300">conditions d'utilisation</a>
        et notre
        <a href="#" class="font-semibold text-indigo-400 hover:text-indigo-300">politique de confidentialité</a>.
      </p>

    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      currentStep: 1,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirm: '',
      },
      errors: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        confirm: null,
      },
    };
  },
  methods: {
    validateStep() {
      const validators = {
        1: () => {
          if (!this.form.firstname.trim()) {
            this.errors.firstname = 'Le prénom est requis.';
            return false;
          }
          this.errors.firstname = null;
          return true;
        },
        2: () => {
          if (!this.form.lastname.trim()) {
            this.errors.lastname = 'Le nom est requis.';
            return false;
          }
          this.errors.lastname = null;
          return true;
        },
        3: () => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!this.form.email.trim() || !emailRegex.test(this.form.email)) {
            this.errors.email = 'Veuillez entrer un email valide.';
            return false;
          }
          this.errors.email = null;
          return true;
        },
        4: () => {
          if (this.form.password.length < 6) {
            this.errors.password = 'Le mot de passe doit contenir au moins 6 caractères.';
            return false;
          }
          this.errors.password = null;
          return true;
        },
        5: () => {
          if (this.form.password !== this.form.confirm) {
            this.errors.confirm = 'Les mots de passe ne correspondent pas.';
            return false;
          }
          this.errors.confirm = null;
          return true;
        },
      };

      if (validators[this.currentStep]() && this.currentStep < 5) {
        this.currentStep++;
      }
    },
    submitForm() {
      if (this.currentStep === 5) {
        alert("Formulaire soumis avec succès !");
        console.log(this.form);
      }
    },
  },
};
</script>
