<template>
  <div class="relative">
    <Load></Load>
    <AppHeader></AppHeader>

    <div class="relative isolate px-6 lg:px-8">
      <div class="mx-auto max-w-3xl py-32 sm:py-48 lg:py-56 justify-center" data-aos="fade-up">
        <div class="text-center">
          <h1 class="text-balance text-5xl font-extrabold tracking-tight text-white sm:text-7xl leading-tight">
            <span class="block">Votre allié digital.</span>
            <span class="block archivo-black-regular bg-clip-text text-transparent uppercase" :style="animatedStyle">Le couteau suisse français.</span>
          </h1>
          <p class="mt-8 text-lg font-medium text-gray-50 sm:text-xl/8 leading-relaxed">
            Des sites web uniques, façonnés à l'image de vos idées et de vos ambitions.
            Avec une approche sur-mesure, transformez vos concepts en solutions digitales performantes et esthétiques.
          </p>
        </div>
        <div class="mt-8 flex mx-auto justify-center mb-14 lg:mb-0">
          <a href="#offers" class="relative text-center rounded-full px-6 py-2 uppercase archivo-black-regular text-gray-50 hover:text-pink-500 transition-all ease-in-out ring-1 ring-white  hover:bg-white hover:bg-opacity-60 text-xl">
            Commencer l'aventure
          </a>
        </div>
      </div>


      <div class="absolute left-0 bottom-0 inline-flex w-full">
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
          <path id="wave-path-fg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
        </svg>
      </div>
    </div>
    <img src="@/assets/bg.jpg" alt="" class="absolute inset-0 -z-10 size-full object-cover">
  </div>
  <Platform></Platform>
  <About></About>
  <Partners></Partners>
  <Testimonials></Testimonials>
  <Offers></Offers>
  <AppFooter></AppFooter>
</template>

<script setup>
import { ref } from 'vue'
import AppHeader from "@/components/Essentials/AppHeader.vue";
import { gsap } from 'gsap';
import {onMounted} from 'vue'
import AppFooter from "@/components/Essentials/AppFooter.vue";
import About from "@/components/About/About.vue";
import Offers from "@/components/Offers/Offers.vue";
import Testimonials from "@/components/Testimonials/Testimonials.vue";
import Partners from "@/components/Partners/Partners.vue";
import Load from "@/components/Essentials/Banner.vue";
import Platform from "@/components/Platform/Platform.vue";
import AOS from 'aos';
import 'aos/dist/aos.css';


const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;

  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }

  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  requestAnimationFrame(animateGradient);
}
const wavePath = document.getElementById('wave-path');

onMounted(() => {
  const wavePathFg = document.getElementById('wave-path-fg');

  if (wavePathFg) {
    gsap.to(wavePathFg, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }
  animateGradient();
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
  });
});

const mobileMenuOpen = ref(false)
</script>