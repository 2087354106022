<template>
  <div>
    <button type="button" class="relative mb-20 block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <svg class="mx-auto size-12 text-gray-400 lucide lucide-panel-top" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <rect width="18" height="18" x="3" y="3" rx="2"/>
        <path d="M3 9h18"/>
      </svg>
      <span class="mt-2 block text-sm font-semibold text-gray-900">Nouveau devis</span>
    </button>
    <div v-for="project in projects" :key="project.id" class="border-b pb-8 mb-12">
      <h2>{{ project.type }}</h2>
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-2xl font-extrabold archivo-black-regular text-gray-900 tracking-tight uppercase">
          {{ project.name }}
        </h1>
        <p :class="[statusesProjects[project.status], 'px-2 py-1 text-xs font-medium rounded-md']">
          {{ project.status }}
        </p>
      </div>

      <div class="flex justify-between text-sm text-gray-600">
        <span><strong>Livraison prévue :</strong> {{ project.deliveryDate }}</span>
        <p :class="[statusesDate[project.drop], 'px-2 py-1 rounded-md']">
          {{ project.drop }}
        </p>
      </div>

      <div class="mt-6">
        <span class="text-gray-900 uppercase archivo-black-regular">Progression</span>
        <nav aria-label="Progression" class="mt-4">
          <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
            <li v-for="(step, index) in project.steps" :key="step.id" class="md:flex-1">
              <a
                  :href="step.href"
                  :class="getStepClass(step, index, project)"
                  :aria-current="step.status === 'current' ? 'step' : null"
              >
                <span class="text-xs font-medium text-gray-600">{{ step.id }}</span>
                <span class="text-sm font-medium">{{ step.name }}</span>
              </a>
            </li>
          </ol>
        </nav>
      </div>

      <div class="flex justify-end gap-4 mt-8">
        <button
            type="button"
            class="rounded bg-gray-500 px-4 py-2 text-xs font-semibold text-white hover:bg-gray-400"
        >
          Documents
        </button>
        <button
            type="button"
            class="rounded bg-gray-500 px-4 py-2 text-xs font-semibold text-white hover:bg-gray-400"
        >
          Voir
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

// Statuts des projets et dates
const statusesProjects = {
  Paiement: "text-green-700 bg-green-50 ring-green-600/20",
  "Attente de paiement": "text-red-600 bg-red-50 ring-red-500/10",
  Archivé: "text-indigo-800 bg-indigo-50 ring-indigo-600/20",
  "En attente": "text-neutral-800 bg-neutral-50 ring-neutral-600/20",
};

const statusesDate = {
  Livré: "text-sky-700 bg-sky-50 ring-sky-600/20",
  "En cours": "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
  "En attente": "text-neutral-800 bg-neutral-50 ring-neutral-600/20",
};

const projects = [
  {
    id: "1",
    type: "Personnalisé",
    name: "Orféo",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad asperiores...",
    status: "Paiement", // En attente du premier paiement
    drop: "En cours",
    deliveryDate: "12 janvier 2025",
    steps: [
      { id: "1", name: "Choix de la formule", href: "#", status: "complete" },
      { id: "2", name: "Entretien avec le client", href: "#", status: "complete" },
      { id: "3", name: "Validation du projet", href: "#", status: "complete" },
      { id: "4", name: "Premier paiement", href: "#", status: "current" }, // Premier paiement
      { id: "5", name: "Développement en cours", href: "#", status: "upcoming" },
      { id: "6", name: "Deuxième paiement", href: "#", status: "upcoming" }, // Deuxième paiement
      { id: "7", name: "Livraison", href: "#", status: "upcoming" },
    ]
  },
  {
    id: "2",
    type: "Standard",
    name: "Adore",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad asperiores...",
    status: "Attente de paiement", // En attente du premier paiement
    drop: "En cours",
    deliveryDate: "En attente",
    steps: [
      { id: "1", name: "Choix de la formule", href: "#", status: "complete" },
      { id: "2", name: "Entretien avec le client", href: "#", status: "complete" },
      { id: "3", name: "Validation du projet", href: "#", status: "current" },
      { id: "4", name: "Paiement", href: "#", status: "upcoming" }, // Premier paiement
      { id: "5", name: "Développement en cours", href: "#", status: "upcoming" },
      { id: "6", name: "Livraison", href: "#", status: "upcoming" },
    ]
  },
  {
    id: "3",
    type: "Personnalisé",
    name: "Fioto",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad asperiores...",
    status: "En attente", // En attente du premier paiement
    drop: "En attente",
    deliveryDate: "En attente",
    steps: [
      { id: "1", name: "Choix de la formule", href: "#", status: "complete" },
      { id: "2", name: "Entretien avec le client", href: "#", status: "current" },
      { id: "3", name: "Validation du projet", href: "#", status: "upcoming" },
      { id: "4", name: "Premier paiement", href: "#", status: "upcoming" }, // Premier paiement
      { id: "5", name: "Développement en cours", href: "#", status: "upcoming" },
      { id: "6", name: "Deuxième paiement", href: "#", status: "upcoming" }, // Deuxième paiement
      { id: "7", name: "Livraison", href: "#", status: "upcoming" },
    ]
  },
];

// Fonction pour gérer les classes des étapes
const getStepClass = (step, index, project) => {
  if (step.status === "complete") {
    return "group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4";
  } else if (step.status === "current") {
    return "flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 text-indigo-600 font-semibold";
  } else if (step.status === "upcoming" && isFirstUpcoming(index, project)) {
    return "group flex flex-col border-l-4 border-gray-200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 border-animate-blink";
  } else {
    return "group flex flex-col border-l-4 border-gray-200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4";
  }
};

const isFirstUpcoming = (index, project) => {
  return project.steps.findIndex((step) => step.status === "upcoming") === index;
};
</script>

<style>
@keyframes blink {
  0%,
  100% {
    border-color: #4f46e5;
  }
  50% {
    border-color: #a5b4fc;
  }
}

.border-animate-blink {
  animation: blink 1s infinite;
}

.text-indigo-600 {
  color: #4f46e5;
}

.font-semibold {
  font-weight: 600;
}
</style>
