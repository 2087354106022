<script setup>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Load from "@/components/Essentials/Banner.vue";
import {onMounted, ref} from "vue";
import {gsap} from "gsap";
import PlayerVideo from "@/components/Essentials/PlayerVideo.vue";
import Newsletter from "@/components/Essentials/Newsletter.vue";


const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;

  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }

  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  requestAnimationFrame(animateGradient);
}

const wavePath = document.getElementById('wave-path');

onMounted(() => {
  const wavePathFg = document.getElementById('wave-path-fg');

  if (wavePathFg) {
    gsap.to(wavePathFg, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }
  animateGradient();
});
</script>

<template>
  <div class="relative">
    <Load></Load>
    <AppHeader></AppHeader>
    <div class="relative isolate px-6 lg:px-8 pb-56">
      <div class="absolute left-0 bottom-0 inline-flex w-full">
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
          <path id="wave-path-fg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
        </svg>
      </div>
    </div>
    <img src="@/assets/bg.jpg" alt="" class="absolute inset-0 -z-10 size-full object-cover">
  </div>
  <div class="w-full max-w-7xl mx-auto flex flex-col">
    <div class="max-w-4xl px-6 sm:px-0">
      <a href="/podcasts" class="archivo-black-regular" :style="animatedStyle">Retour</a>
    </div>
    <PlayerVideo video-src="https://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_640x360.m4v"></PlayerVideo>
    <h1 class="text-3xl lg:text-4xl mt-8 archivo-black-regular uppercase px-6 sm:px-0">Nouveaux projets 2025, business, voyages, perso, pro</h1>
  </div>
  <Newsletter></Newsletter>
  <AppFooter></AppFooter>
</template>