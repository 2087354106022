<template>
  <div class="mx-auto max-w-7xl px-6 lg:px-8 flex gap-32 flex-col" data-aos="fade-up">
    <div class="flex gap-12 flex-col">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl archivo-black-regular uppercase" :style="animatedStyle">Dernière sortie</h2>
        <p class="mt-2 text-lg/8 text-gray-600">
          Les podcasts ont pour but de partager des expériences, donner des conseils, et bien plus encore.
        </p>
      </div>
      <div v-if="videos.length > 0" class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <a :href="videos[0].href" class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-[56.25%] sm:pt-[56.25%] lg:pt-[56.25%]">
          <video :src="videos[0].videoUrl" autoplay muted loop playsinline class="absolute inset-0 -z-10 w-full h-full object-cover"></video>
          <div class="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
          <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

          <div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm/6 text-gray-300">
            <time :datetime="videos[0].datetime" class="mr-8">{{ videos[0].date }}</time>
          </div>
          <h3 class="mt-3 text-lg/6 font-semibold text-white">
            <span class="archivo-black-regular">
              <span class="absolute inset-0"></span>
              {{ videos[0].title }}
            </span>
          </h3>
        </a>
      </div>
    </div>

    <div>
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-balance text-center text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl archivo-black-regular uppercase" :style="animatedStyle">Les Podcasts</h2>
      </div>
      <div class="mx-auto mt-8 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <a :href="video.href" v-for="video in paginatedVideos" :key="video.id" class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-96 sm:pt-48 lg:pt-96">
          <video :src="video.videoUrl" autoplay muted loop playsinline class="absolute inset-0 -z-10 size-full object-cover"></video>
          <div class="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
          <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

          <div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm/6 text-gray-300">
            <time :datetime="video.datetime" class="mr-8">{{ video.date }}</time>
          </div>
          <h3 class="mt-3 text-lg/6 font-semibold text-white">
            <span class="archivo-black-regular">
              <span class="absolute inset-0"></span>
              {{ video.title }}
            </span>
          </h3>
        </a>
      </div>
    </div>

    <nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 my-24 max-w-2xl w-full mx-auto">
      <div class="-mt-px flex w-0 flex-1">
        <a @click.prevent="goToPage(currentPage - 1)" v-if="currentPage > 1" class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer archivo-black-regular">
          <ArrowLongLeftIcon class="mr-3 size-5 text-gray-400" aria-hidden="true" />
          Précedent
        </a>
      </div>
      <div class="hidden md:-mt-px md:flex">
        <a v-for="page in totalPages" :key="page" @click.prevent="goToPage(page)" :class="{'border-t-2 border-indigo-500 text-indigo-600': page === currentPage, 'border-t-2 border-transparent text-gray-500 hover:text-gray-700': page !== currentPage}" class="inline-flex items-center cursor-pointer px-4 pt-4 text-sm font-medium archivo-black-regular">
          {{ page }}
        </a>
      </div>
      <div class="-mt-px flex w-0 flex-1 justify-end">
        <a @click.prevent="goToPage(currentPage + 1)" v-if="currentPage < totalPages" class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700  cursor-pointer archivo-black-regular">
          Suivant
          <ArrowLongRightIcon class="ml-3 size-5 text-gray-400" aria-hidden="true" />
        </a>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/vue/20/solid';

const videos = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 2,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 3,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 4,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 5,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 6,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 7,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
  {
    id: 8,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    videoUrl: 'https://api.meritemaritime-fnmm.com/assets/herve/herve.mp4',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },

];

const videosPerPage = 6;
const currentPage = ref(1);

const totalPages = computed(() => Math.ceil(videos.length / videosPerPage));

const paginatedVideos = computed(() => {
  const start = (currentPage.value - 1) * videosPerPage;
  const end = start + videosPerPage;
  return videos.slice(start, end);
});

function goToPage(page) {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
}


const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;

  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }

  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  requestAnimationFrame(animateGradient);
}

onMounted(() => {
  animateGradient();
});
</script>
