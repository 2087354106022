import { createRouter, createWebHistory } from 'vue-router'
import Home from "@/views/Home.vue";
import Signin from "@/views/Signin.vue";
import User from "@/views/User.vue";
import Podcasts from "@/views/Podcasts.vue";
import Articles from "@/views/Articles.vue";
import Article from "@/views/Article.vue";
import Podcast from "@/views/Podcast.vue";
import Standard from "@/views/Standard.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/espace-de-connexion',
    name: 'signin',
    component: Signin
  },
  {
    path: '/mon-espace',
    name: 'user',
    component: User
  },
  {
    path: '/podcasts',
    name: 'podcasts',
    component: Podcasts
  },
  {
    path: '/podcasts/:id',
    name: 'podcast',
    component: Podcast
  },
  {
    path: '/articles',
    name: 'articles',
    component: Articles
  },
  {
    path: '/articles/:id',
    name: 'article',
    component: Article
  },
  {
    path: '/demande-standard',
    name: 'standard',
    component: Standard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
