<template>
  <div class="py-56 sm:py-72 relative">
    <div class="absolute left-0 bottom-0 inline-flex w-full">
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
        <path id="wave-partners-path-fg" ref="wavePartnersPathFg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
      </svg>
    </div>
    <div class="absolute left-0 top-0 rotate-180 inline-flex w-full">
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="w-full h-auto">
        <path id="wave-partners-top-path-fg" ref="wavePartnersTopPathFg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#FFFFFF"></path>
      </svg>
    </div>
    <img src="@/assets/bg.jpg" alt="" class="absolute inset-0 -z-10 size-full object-cover rotate-180">
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { gsap } from "gsap";

const wavePartnersPathFg = ref(null);
const wavePartnersTopPathFg = ref(null);

onMounted(() => {
  if (wavePartnersPathFg.value) {
    gsap.to(wavePartnersPathFg.value, {
      duration: 3,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }

  if (wavePartnersTopPathFg.value) {
    gsap.to(wavePartnersTopPathFg.value, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }
});
</script>
