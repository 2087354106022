<template>
  <svg preserveAspectRatio="none" class="w-full h-auto"  xmlns="http://www.w3.org/2000/svg">
    <path id="wave-footer-path-bg" d="M 0 120 C 300 150 600 90 900 120 C 1200 150 1500 90 1800 120 L 2000 250 L 0 250 Z" fill="#8E5BF5" opacity="0.3"></path>
    <path id="wave-footer-path-fg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 2000 150 L 0 150 Z" fill="#000000"></path>
  </svg>
  <footer class="bg-black">
    <div class="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8"  data-aos="fade-up">
        <img class="h-14" src="@/assets/icon.png"/>
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm/6 font-semibold text-white">Produits</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.solutions" :key="item.name">
                  <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm/6 font-semibold text-white">Liens</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.platform" :key="item.name">
                  <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm/6 font-semibold text-white">L'entreprise</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.company" :key="item.name">
                  <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm/6 font-semibold text-white">Légal</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.legal" :key="item.name">
                  <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { gsap } from 'gsap';

import {defineComponent, h, onMounted} from 'vue'

const wavePath = document.getElementById('wave-footer-path');

// Animation avec GSAP
onMounted(() => {
  const wavePathBg = document.getElementById('wave-footer-path-bg');
  const wavePathFg = document.getElementById('wave-footer-path-fg');

  if (wavePathBg) {
    gsap.to(wavePathBg, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }

  if (wavePathFg) {
    gsap.to(wavePathFg, {
      duration: 5,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 2000 150
          L 0 150 Z
        `,
      },
    });
  }
});

const navigation = {
  solutions: [
    { name: 'Kuube', href: '#' },
    { name: 'Etudik', href: '#' },
  ],
  platform: [
    { name: 'Podcasts', href: '#' },
    { name: 'Offres', href: '#' },
    { name: 'Plateforme', href: '#' },
  ],
  company: [
    { name: 'À propos', href: '#' },
    { name: 'LinkedIn', href: '#' },
    { name: 'Nous contacter', href: '#' },
  ],
  legal: [
    { name: 'Mentions légales', href: '#' },
    { name: 'Politique de confidentialité', href: '#' },
    { name: 'Conditions générales d\'utilisation (CGU)', href: '#' },
    { name: 'Licences', href: '#' },
  ],
}
</script>