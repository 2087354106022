<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8" data-aos="fade-up">
      <div class="mx-auto max-w-4xl text-center py-12">
        <h1 :style="animatedStyle" class="mt-2 text-balance text-4xl font-semibold tracking-tight relative text- sm:text-5xl uppercase archivo-black-regular">Vos avis comptent pour nous !</h1>
      </div>
      <div class="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
        <div class="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
          <div v-for="testimonial in testimonials" :key="testimonial.author.handle" class="pt-8 sm:inline-block sm:w-full sm:px-4">
            <figure class="rounded-2xl bg-gray-50 p-8 text-sm/6">
              <blockquote class="text-gray-900">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img class="size-10 rounded-full bg-gray-50" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold text-gray-900">{{ testimonial.author.name }}</div>
                  <div class="text-gray-600">{{ `@${testimonial.author.handle}` }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";

const testimonials = [
  {
    body: 'Une agence exceptionnelle ! L’équipe a su capter l’essence de notre projet et l’a parfaitement concrétisé. Notre site web est à la fois esthétique, rapide et facile à utiliser. La communication avec eux a été fluide à chaque étape.',
    author: {
      name: 'Julien Dupont',
      handle: 'julien_dpt',
      imageUrl:
          'https://randomuser.me/api/portraits/men/32.jpg',
    },
  },
  {
    body: 'Grâce à cette équipe, notre projet a vu le jour plus rapidement que prévu. Le suivi a été irréprochable et ils ont su être force de proposition tout au long de notre collaboration.',
    author: {
      name: 'Sophie Leroy',
      handle: 'sophie_leroy',
      imageUrl:
          'https://randomuser.me/api/portraits/women/45.jpg',
    },
  },
  {
    body: 'Nous avons fait appel à cette agence pour la refonte de notre site. Ils ont su nous conseiller et nous proposer des solutions adaptées à nos besoins. Le résultat est au-delà de nos attentes, un site moderne et performant. Je recommande vivement.',
    author: {
      name: 'Marc Lefevre',
      handle: 'marc_lefevre',
      imageUrl:
          'https://randomuser.me/api/portraits/men/71.jpg',
    },
  },
  {
    body: 'J’étais sceptique au début, mais cette équipe a totalement surpassé mes attentes. Ils ont pris le temps d’écouter mes besoins et ont livré un site élégant et intuitif. Merci pour cette belle expérience.',
    author: {
      name: 'Lucie Bernard',
      handle: 'lucie_bernard',
      imageUrl:
          'https://randomuser.me/api/portraits/women/12.jpg',
    },
  },
  {
    body: 'Notre collaboration avec cette équipe a été un véritable plaisir. Ils ont su être réactifs, créatifs et à l’écoute. Le résultat est parfait, et notre site fonctionne maintenant à merveille. Une équipe en qui on peut avoir confiance.',
    author: {
      name: 'Antoine Richard',
      handle: 'antoine_richard',
      imageUrl:
          'https://randomuser.me/api/portraits/men/65.jpg',
    },
  },
  {
    body: 'En tant que PME, nous avons voulu travailler avec une agence capable de comprendre nos enjeux spécifiques. Ils ont non seulement créé un site web, mais ont aussi optimisé notre présence en ligne. Très satisfait de la collaboration.',
    author: {
      name: 'Chloé Moreau',
      handle: 'chloe_moreau',
      imageUrl:
          'https://randomuser.me/api/portraits/women/88.jpg',
    },
  },
]


const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;

  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }

  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;

  requestAnimationFrame(animateGradient);
}

onMounted(() => {
  animateGradient();
});
</script>