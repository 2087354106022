<template>
  <div class="flex min-h-full h-dvh flex-1 overflow-hidden">
    <div class="flex flex-1 flex-col relative justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white z-20">
      <a href="/" data-aos="fade-right" class="text-gray-900 absolute m-6 top-0 left-0 z-40 flex items-center gap-2 border border-gray-900 rounded-full p-2 px-6 hover:text-pink-500 transition-all ease-in-out hover:border-pink-500">&larr; Retour</a>
      <div class="absolute bottom-0 left-0 w-full">
        <a href="/" class="absolute z-20 bottom-0 left-0 p-2 items-center flex gap-2 text-white">
          <img src="@/assets/icon.png" class="h-12">
          <h1 class="archivo-black-regular flex items-center gap-1">HBC GROUP<small>- © Tous droits réservés</small></h1>
        </a>
        <div class="relative rotate-180 left-0 bottom-0 inline-flex w-full">
          <svg width="100%" height="150" xmlns="http://www.w3.org/2000/svg">
            <path id="wave-path-fg" d="M 0 70 C 300 90 600 50 900 70 C 1200 90 1500 50 1800 70 L 1800 150 L 0 150 Z" fill="#FFFFFF"></path>
          </svg>
        </div>
        <img src="@/assets/bg.jpg" data-aos="fade" class="absolute top-0 left-0 -z-10">
      </div>

      <div class="mx-auto w-full max-w-sm lg:w-96 relative" data-aos="fade-up">
        <div>
          <h2 v-if="isSignin" class="mt-8 archivo-black-regular text-3xl lg:text-5xl font-bold tracking-tight uppercase" :style="animatedStyle">
            Accéder à votre compte
          </h2>
          <h2 v-else class="mt-8 archivo-black-regular text-3xl lg:text-5xl font-bold tracking-tight uppercase" :style="animatedStyle">
            Création de votre compte
          </h2>
        </div>

        <div class="mt-4 sm:mt-8">
          <SigninForm v-if="isSignin" @switchForm="toggleForm" />
          <SignupForm v-else @switchForm="toggleForm" />
        </div>
      </div>
    </div>

    <div class="relative hidden w-0 flex-1 lg:block" data-aos="fade">
      <img ref="zoomImage" class="absolute inset-0 size-full object-cover" src="@/assets/app-signin.png" alt="" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { gsap } from "gsap";
import SigninForm from "@/components/Account/SigninForm.vue";
import SignupForm from "@/components/Account/SignupForm.vue";
import AOS from 'aos';
import 'aos/dist/aos.css';

const zoomImage = ref(null);

const isSignin = ref(true);
function toggleForm() {
  isSignin.value = !isSignin.value;
}

const gradientPosition = ref(0);
const direction = ref(1);
const animatedStyle = ref({
  backgroundImage: "linear-gradient(to right, #6366f1, #a855f7, #ec4899)",
  backgroundSize: "200% 200%",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  backgroundPosition: "0% 50%",
});

function animateGradient() {
  gradientPosition.value += direction.value * 1.3;
  if (gradientPosition.value >= 100 || gradientPosition.value <= 0) {
    direction.value *= -1;
  }
  animatedStyle.value.backgroundPosition = `${gradientPosition.value}% 50%`;
  requestAnimationFrame(animateGradient);
}

onMounted(() => {
  const wavePathFg = document.getElementById("wave-path-fg");

  if (wavePathFg) {
    gsap.to(wavePathFg, {
      duration: 2,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      attr: {
        d: `
          M 0 70
          C 300 30 600 120 900 70
          C 1200 30 1500 120 1800 70
          L 1800 150
          L 0 150 Z
        `,
      },
    });
  }

  gsap.to(zoomImage.value, {
    scale: 1.05,
    repeat: -1,
    yoyo: true,
    duration: 5,
    ease: "power1.inOut",
  });
  animateGradient();
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
  });
});
</script>

<style scoped>
.size-full {
  width: 100%;
  height: 100%;
}
</style>
