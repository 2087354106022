<template>
  <div class="relative w-full max-w-7xl mx-auto bg-black overflow-hidden rounded-lg mt-12 border border-gray-200 px-6 sm:px-0">
    <!-- Vidéo -->
    <video
        ref="videoPlayer"
        class="w-full h-auto"
        :src="videoSrc"
        @timeupdate="updateProgress"
        @loadedmetadata="updateDuration"
        @click="togglePlay"
    ></video>

    <!-- Barre de progression -->
    <div v-if="controlsVisible" class="absolute bottom-[56px] left-0 w-full z-20 px-4 items-center">
      <div
          class="relative w-full h-2 bg-gray-500 rounded-full cursor-pointer"
          @click="seekTo"
      >
        <!-- Barre de progression complète -->
        <div
            class="absolute top-0 left-0 h-2 bg-gradient-to-r from-purple-500 to-purple-700"
            :style="{ width: `${(currentTime / duration) * 100}%` }"
        ></div>
        <!-- Curseur -->
        <div
            class="absolute top-1/2 -translate-y-1/2 h-3 w-3 bg-white border-[2px] border-purple-700 rounded-full cursor-pointer"
            :style="{ left: `${(currentTime / duration) * 100}%` }"
            @mousedown="startSeeking"
        ></div>
      </div>
    </div>

    <!-- Contrôles -->
    <div v-if="controlsVisible" class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent p-4 flex items-center gap-4 z-10 justify-between">
      <div class="flex items-center gap-4">
        <!-- Play/Pause -->
        <button @click="togglePlay" class="text-white hover:text-purple-500">
          <component
              :is="isPlaying ? 'LucidePause' : 'LucidePlay'"
              class="w-6 h-6"
          />
        </button>

        <!-- Volume -->
        <div
            class="relative flex items-center text-white group"
            @mouseenter="hoveringVolume = true"
            @mouseleave="hoveringVolume = false"
        >
          <button @click="toggleMute" class="hover:text-purple-500">
            <component
                :is="volumeIcon"
                class="w-6 h-6"
            />
          </button>
          <!-- Barre de volume -->
          <div
              v-if="hoveringVolume"
              :class="{
            'absolute top-1/2 left-full ml-2 -translate-y-1/2': !hoveringVolume,
            'relative ml-2': hoveringVolume,
          }"
              class="flex items-center gap-2 transition-all"
          >
            <input
                type="range"
                class="w-[100px] h-1 bg-gray-700 rounded cursor-pointer"
                min="0"
                max="1"
                step="0.1"
                v-model="volume"
                @input="changeVolume"
            />
          </div>
        </div>

        <!-- Timer -->
        <div class="text-sm text-white">
          {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
        </div>
      </div>

      <div class="flex items-center gap-4">
        <!-- Paramètres -->
        <button @click="toggleSettings" class="text-white hover:text-purple-500">
          <LucideSettings class="w-6 h-6" />
        </button>

        <!-- Plein écran -->
        <button @click="toggleFullscreen" class="text-white hover:text-purple-500">
          <LucideMaximize class="w-6 h-6" />
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import {
  LucidePlay,
  LucidePause,
  LucideVolumeX,
  LucideVolume1,
  LucideVolume2,
  LucideSettings,
  LucideMaximize,
} from "lucide-vue-next";

export default {
  components: {
    LucidePlay,
    LucidePause,
    LucideVolumeX,
    LucideVolume1,
    LucideVolume2,
    LucideSettings,
    LucideMaximize,
  },
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
  },
  setup() {
    const videoPlayer = ref(null);
    const isPlaying = ref(false);
    const currentTime = ref(0);
    const duration = ref(0);
    const volume = ref(0.5);
    const isMuted = ref(false);
    const isSeeking = ref(false);
    const hoveringVolume = ref(false);
    const isLoading = ref(false);
    const controlsVisible = ref(true);

    let inactivityTimeout;

    const updateProgress = () => {
      if (!isSeeking.value) {
        currentTime.value = videoPlayer.value.currentTime;
        isLoading.value = videoPlayer.value.buffered.length === 0;
      }
    };

    const updateDuration = () => {
      duration.value = videoPlayer.value.duration;
      isLoading.value = false;
    };

    const togglePlay = () => {
      if (videoPlayer.value.paused) {
        videoPlayer.value.play();
        isPlaying.value = true;
      } else {
        videoPlayer.value.pause();
        isPlaying.value = false;
      }
    };

    const startSeeking = () => {
      isSeeking.value = true;
      document.addEventListener("mousemove", seek);
      document.addEventListener("mouseup", stopSeeking);
    };

    const stopSeeking = () => {
      isSeeking.value = false;
      document.removeEventListener("mousemove", seek);
      document.removeEventListener("mouseup", stopSeeking);
    };

    const seek = (event) => {
      const progressBar = event.target.parentElement.getBoundingClientRect();
      const seekTime =
          ((event.clientX - progressBar.left) / progressBar.width) *
          duration.value;
      videoPlayer.value.currentTime = seekTime;
      currentTime.value = seekTime;
    };

    const seekTo = (event) => {
      const progressBar = event.target.getBoundingClientRect();
      const clickPosition = event.clientX - progressBar.left;
      const clickPercentage = clickPosition / progressBar.width;
      const seekTime = clickPercentage * duration.value;
      videoPlayer.value.currentTime = seekTime;
      currentTime.value = seekTime;
    };

    const changeVolume = (event) => {
      const newVolume = event.target.value;
      volume.value = newVolume;
      videoPlayer.value.volume = newVolume;
      isMuted.value = newVolume === 0;
    };

    const toggleMute = () => {
      if (isMuted.value) {
        videoPlayer.value.volume = volume.value || 0.5;
        isMuted.value = false;
      } else {
        videoPlayer.value.volume = 0;
        isMuted.value = true;
      }
    };

    const toggleSettings = () => {
      alert("Paramètres à ajouter");
    };

    const toggleFullscreen = () => {
      if (videoPlayer.value.requestFullscreen) {
        videoPlayer.value.requestFullscreen();
      }
    };

    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    };

    const volumeIcon = computed(() => {
      if (isMuted.value || volume.value === 0) return "LucideVolumeX";
      if (volume.value <= 0.5) return "LucideVolume1";
      return "LucideVolume2";
    });

    const resetInactivityTimer = () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        controlsVisible.value = false;
      }, 3000); // 3 secondes d'inactivité
    };

    const handleMouseMove = () => {
      controlsVisible.value = true;
      resetInactivityTimer();
    };

    onMounted(() => {
      document.addEventListener("mousemove", handleMouseMove);
    });

    onBeforeUnmount(() => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      document.removeEventListener("mousemove", handleMouseMove);
    });

    return {
      videoPlayer,
      isPlaying,
      currentTime,
      duration,
      volume,
      isMuted,
      isSeeking,
      hoveringVolume,
      isLoading,
      controlsVisible,
      togglePlay,
      updateProgress,
      updateDuration,
      startSeeking,
      stopSeeking,
      seek,
      seekTo,
      changeVolume,
      toggleMute,
      toggleSettings,
      toggleFullscreen,
      formatTime,
      volumeIcon,
    };
  },
};
</script>
