<template>
  <hr class="max-w-7xl mx-auto mt-24">
  <div class="px-6">
    <div class="relative sm:rounded-lg max-w-7xl mx-auto my-24">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg font-semibold  archivo-black-regular uppercase text-white">Abonnez-vous à notre newsletter</h3>
        <div class="mt-2 max-w-xl sm:text-lg text-sm text-gray-50">
          <p>Recevez des podcasts exclusifs et des articles approfondis sur l'actualité technologique et le monde des affaires.</p>
        </div>
        <form class="mt-5 sm:flex sm:items-center">
          <div class="w-full sm:max-w-xs">
            <input type="email" name="email" id="email" aria-label="Email" class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" placeholder="moi@example.com" />
          </div>
          <button type="submit" class="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto">S'inscrire</button>
        </form>
      </div>
      <img src="@/assets/bg.jpg" alt="" class="absolute inset-0 -z-10 size-full rounded-lg object-cover">
    </div>
  </div>
</template>
<script setup>
</script>